import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';

import Layout from '../components/Layout';
import RegionList from '../components/RegionList';
import Accordion from '../components/Accordion';
import RegionMenu from '../components/RegionMenu';
import locations from '../data/locations.json';
import groups from '../data/groups.json';


function Locations() {
  const meta = [
    {
      name: 'description',
      content: 'Enjoy over 45 restaurants in Trinidad and Tobago'
    },
    {
      name: 'keywords',
      content: 'Subway, Trinidad, Locations, Tobago'
    }
  ];

  const [active, setActive] = useState(null);
  const [filtered, setFiltered] = useState(locations);
  
  const onClick = ({ currentTarget }) => setActive(currentTarget.dataset.filter);
  const onToggle = ({ currentTarget }) => {
    const { filter } = currentTarget.dataset;
    setActive(filter === active ? null : filter);
  }

  useEffect(() => {
    if (active) {
      setFiltered(locations.filter(i => i.region === active));
    } else {
      setFiltered(locations);
    }
  }, [active]);

  return (
    <Layout title="Locations" meta={meta} className="pb-5">
      <Container fluid className="d-block d-md-none">
        <Accordion
          items={locations}
          groups={groups}
          active={active}
          onClick={onToggle}
        />
      </Container>
      <Container fluid className="d-none d-md-block">
        <Row>
          <Col xs={12} md={2}>
            <RegionMenu active={active} onClick={onClick} />
          </Col>
          <Col>
            <RegionList items={filtered} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Locations;
